import React, { useState, useEffect } from "react"
import axios from "axios";
import SearchResult from "../../../templates/search-result-template"
import { UserObjectStoreProvider } from "@myaccountServices";
import { apiUrl, getToken } from '../../../services/store/utils'

/* Get User Objects*/
const baseURL = apiUrl()
const token = getToken()

// With header call
const authInstance = axios.create({
  baseURL: baseURL,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

const getUserObjects = () => {
  return authInstance.get(`/stb-users/userobjects`);
}

// markup
const SearchResultComp = (props) => {

  // For myaccount
  useEffect(() => {
    if(token) {
      getUserObjects().then(
        (userObjectsData) =>{
          setUserObjects(userObjectsData.data.data)
        }
      );
    }
  }, [])

  const [userObjects, setUserObjects] = useState({});
  //const authuser = isAuthenticated()

  return (
    <SearchResult 
      title={"Property for sale"} 
      department={"residential"} 
      search_type={"sales"} 
      status={["For Sale","Sold", "Under Offer"]} 
      path={"/property/for-sale/"}
      location={props.location}
      userObjects={userObjects}
    />
  )
}

const PropertForSale = (props) => {
  return(
      <UserObjectStoreProvider>
          <SearchResultComp {...props} />
      </UserObjectStoreProvider>
  )
}

export default PropertForSale